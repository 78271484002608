"use client";

import { Flex, Row } from "@primitives/layout";
import { Link } from "@primitives/link";
import { Text } from "@primitives/text";
import Image from "next/image";

const links = [
  { label: "Home", href: "/" },
  { label: "Docs", href: "/docs" },
  { label: "Blog", href: "/blog" },
  { label: "Pricing", href: "/pricing" },
  { label: "Terms of services", href: "/policy/terms" },
  { label: "Privact policy", href: "/policy/privacy" },
];

export function Footer() {
  return (
    <footer className="bg-default mt-auto border-t px-4 py-8 md:py-10 lg:px-6">
      <Flex className="mx-auto max-w-[var(--max-width)] flex-col-reverse justify-between gap-8 md:flex-col lg:flex-row">
        <Flex className="flex-col items-center gap-3 md:flex-row">
          <Row className="items-center gap-3">
            <Image
              alt=""
              src="/outpost-glyph.svg"
              width={16}
              height={16}
              className="shrink-0 dark:invert"
            />
            <Text color="soft">© 2024 Outpost Innovations, Inc.</Text>
          </Row>
        </Flex>
        <Row className="flex-wrap justify-center gap-2 sm:justify-start">
          {links.map((i) => (
            <Link
              className="whitespace-nowrap px-2 py-1"
              href={i.href}
              key={i.label}
            >
              {i.label}
            </Link>
          ))}
        </Row>
      </Flex>
    </footer>
  );
}
